<template lang="html">
  <div class="impresszum">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-list-item two-line dark>
          <v-list-item-content>
            <v-list-item-title>Az oldal tulajdonosa</v-list-item-title>
            <v-list-item-subtitle>dr. Zsigmond Ildikó</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line dark>
          <v-list-item-content>
            <v-list-item-title>Kapcsolat</v-list-item-title>
            <v-list-item-subtitle>izs.ildikozsigmond@gmail.com</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line dark>
          <v-list-item-content>
            <v-list-item-title>Tárhely</v-list-item-title>
            <v-list-item-subtitle>
              <a href="https://firebase.google.com/terms" target="_blank">Firebase hosting</a><br/><br/>
              <p>
                Google LLC<br/>
                1600 Amphitheatre Parkway<br/>
                Mountain View, CA 94043<br/>
                USA<br/>
              </p>
              <a href="https://support.google.com/legal/answer/3110420" target="_blank">Jogi információ és kapcsolat</a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line dark>
          <v-list-item-content>
            <v-list-item-title>Üzenetek tárhelye</v-list-item-title>
            <v-list-item-subtitle>
              <a href="https://www.form.io/contact" target="_blank"> Form.io </a><br/><br/>
              <p>
                2121 N Pearl St<br/>
                Dallas<br/>
                TX 75201<br/>
              </p>
              <a href="https://www.form.io/privacy" target="_blank">Adatkezelés</a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  mounted(){
    window.scrollTo(0,0)
    const tl = gsap.timeline()
    tl.to('.v-list-item', {duration: 0.8, opacity:1, stagger:1.2})
  }
}
</script>

<style lang="scss" scoped>
.impresszum{
  padding: 10px 0 30px 0;

  .v-list-item{
    opacity: 0;

    & a,
    & a:link,
    & a:visited
    {
      display: inline-block;
      border-bottom: 1px solid #ccc;

      &:hover{
        border-bottom: 1px solid grey;
      }
    }
  }
}
</style>
